<template>
  <div v-if="$place"
       class="popup-audio-guide">
    <div class="popup-audio-guide__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''"
              :full="$viewport.mobile">
        <p class="popup-audio-guide__title">Аудиогид по маршруту {{$place.title}}</p>
        <p class="popup-audio-guide__subtitle">Прослушайте аудиогид, который подготовили наши
          путешественники</p>
        <AudioPlayer v-for="audio in $place.audios"
                     class="popup-audio-guide__player"
                     :key="audio.id"
                     :params="audio"/>
      </Column>
    </div>
  </div>
  <Loader v-else/>
</template>

<script>
import AudioPlayer from '../../AudioPlayer.vue';

export default {
  name: 'PopupAudioGuide',
  components: { AudioPlayer },
};
</script>
